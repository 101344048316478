function Effects(effects) {
  return ({ getState, dispatch }) => {
    return next => action => {
      const returnValue = next(action);

      const actions = [];
      for (let i = 0; i < effects.length; i++) {
        effects[i](action, getState, action => actions.push(action));
      }

      if (process.env.NODE_ENV === 'development') {
/*        console.log(
          `dispatch ${action.type} ${
            actions.length > 0 ? '=>' : ''
          } ${actions.map(a => a.type).join(', ')}`
        );*/
      }

      setTimeout(() => actions.forEach(action => dispatch(action)), 0);
      return returnValue;
    };
  };
}

export default Effects;
